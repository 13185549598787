@import "../mixins/media";
@import "../mixins/projects";
/*
ログイン

主にログインフォームのスタイルを表示します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 6.1
*/

/*
ログインフォーム

ログインフォームを表示します。

ex [ログイン画面](http://demo3.ec-cube.net/mypage/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-login


Styleguide 6.1.1
*/
.ec-login{
  .ec-content__frame {
    height: 100%;
  }
  .ec-content__frame__inner {
    height: 100%;
    margin: 0 0 20px;
    padding: 20px 16px;
    box-sizing: border-box;
    background: $bg-sub-content;
    @include media_desktop {
      margin: 0;
      padding: 20px;
    }
  }
  & &__icon {
    text-align: center;
  }
  .ec-icon{
    margin-bottom: 10px;
    img {
      width: 90px;
      height: 90px;
      display: inline-block;
    }
  }
  & &__input {
    margin-bottom: 10px;
    .form-control {
      + .form-control {
      margin-top: 10px;
      }
    }
    .ec-checkbox {
      span {
        margin-left: 5px;
        font-weight:normal;
      }
    }
  }
  & &__actions {
    color: #fff;
    width: 100%;
    @include reset_link();
    button {
      border-radius: $border-radius-default;
    }
  }
  & &__link {
    margin-top: 5px;
    margin-left: 0;
    @include media_desktop {
      margin-left: 20px;
    }
  }
  &__new-member {
    button, .ec-blockBtn--action {
      small {
        .ec-layoutRole__right & {
          font-size: 1.1rem;
        }
      }
      span {
        display: block;
        margin-top: 5px;
        font-size: 1.8rem;
      }
    }
  }
  .ec-errorMessage {
    color: $clrRed;
    margin-bottom: 20px;
  }
  .ec-grid3__cell2 {
    @include breakpoints-min(md) {
      margin-right: 18px;
    }
  }
}

.ec-login__input {
  width: 100%;
  @include clearfix;
  dl, dt, dd {
    line-height: 1;
    font-size: 1.3rem;
  }
  dl {
    margin: 0;
    clear: both;
    min-height: 1em;
    + dl {
      margin-top: 10px;
    }
  }
  dt, dd {
    word-break: break-all;
  }
  dt {
    float: left;
    font-weight: bold;
    width: 8em;
    .ec-headerLogin & {
      width: 3em;
    }
  }
  dd {
    width: calc(100% - 9em);
    @include breakpoints-min(md){
      width: calc(100% - 9em);
    }
    .ec-headerLogin & {
      margin-left: 4em;
      width: calc(100% - 4em);
      @include breakpoints-min(md){
        width: calc(100% - 4em);
      }
    }
  }
  .ec-checkbox {
    margin-top: 10px;
    @include clearfix;
    .form-check-input {
      float: left;
      margin-right: 5px;
    }
    .form-check-label {
      display: inline;
    }
  }
}

/*
ゲスト購入

ゲスト購入ボタンとそのフォームを表示します。

ex [ゲスト購入画面](http://demo3.ec-cube.net/shopping/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-guest
hoge

Styleguide 6.1.2
*/
.ec-guest{
  display: table;
  margin: 0;
  height: auto;
  box-sizing: border-box;

  @include media_desktop {
    height: 100%;
    margin: 0 16px;
  }
  & &__inner{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    p {
      margin-bottom: 16px;
    }
  }
  & &__actions {
    display: block;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    @include reset_link();
    a {
      border-radius: $border-radius-default;
    }
  }
  & &__icon{
    font-size: 70px;
    text-align: center;
  }
}
