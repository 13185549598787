@import "./variables";
@import "./clearfix";

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */



//@mixin media_tablet(){
//  @media only screen and (min-width: 768px) {
//    @content;
//  }
//}

@mixin media_desktop(){
  @media only screen and (min-width: 768px) {
    @content;
  }
}

//@mixin media_desktop2(){
//  @media only screen and (min-width: 768px) {
//    @content;
//  }
//}
//
//@mixin media_desktop3(){
//  @media only screen and (min-width: 768px) {
//    @content;
//  }
//}


@mixin container(){
  img{
    width: auto;
    max-width: 100%;
  }
  @include breakpoints-max(md) {
    > div {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
    @include breakpoints-min(md) {
    margin: 0 auto;
    padding-left:  18px;
    padding-right: 18px;
    box-sizing: border-box;
    @include clearfix;
    @include commonStyle();
    width: 100%;
    max-width: $container-max-width;
  }

  //@media (min-width: $desktop) {
  //  width: 720 + 30px;
  //}
  //@media (min-width: $desktop2) {
  //  width: 940 + 30px;
  //}
  //@media (min-width: $desktop3) {
  //  width: 1140 + 30px;
  //}
}
@mixin mypageContainer(){
  margin-right: auto;
  margin-left: auto;
  padding-left:  8px;
  padding-right: 8px;
  box-sizing: border-box;
  @include clearfix;
  @include commonStyle();
  width: 100%;
  //max-width: 1130px;
  @include media_desktop {
    padding-left:  18px;
    padding-right: 18px;
  }
}

@mixin commonStyle(){
  font-size: $font-size-pc;
  line-height: 1.4;
  color: #000000;
  -webkit-text-size-adjust: 100%;

  //a {
  //color: #0092C4;
  //color: #A092C4;
  //text-decoration: none;
  //cursor: pointer;
  //}
  //a:hover,
  //a:focus,
  //a:active { color: #33A8D0;text-decoration: none; outline: none;}


  textarea { /* for chrome fontsize bug */
    font-family: sans-serif;
  }

  //ul, ol {
  //  list-style: none;
  //  margin: 0; padding: 0;
  //}
  //dl, dt, dd, li{
  //  margin: 0; padding: 0;
  //}

  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }



}
