// underlineに少しフェード
//hover要素に記述
//$elementにはhover後に変化する要素を記述(a直下要素のみ)
@mixin hover__text-underline($element:span,$underline-color:#ffffff) {
  #{$element} {
    position: relative;
    display: inline-block;
    text-decoration: none;
    &:after {
      position: absolute;
      bottom: -2px;
      left: 0;
      content: '';
      width: 100%;
      height: 1px;
      opacity: 0;
      visibility: hidden;
      transition: $transition-default;
      background: transparent;
    }
  }
  &:hover, &:active, &:focus {
    > #{$element}:after {
      background: $underline-color;
      opacity: 1;
      visibility: visible;
    }
  }
}




