.ec-sideBanner {
  &__itemlist {
    //.ec-productRole__banner-area & {
    //  display: flex;
    //  flex-wrap: wrap;
    //}
    //@include breakpoints-min(md) {
    //  display: flex;
    //  flex-wrap: wrap;
    //}
    display: flex;
    flex-wrap: wrap;

  }
  &__item {
    width: 49%;
    margin: 0.5%;
    @include breakpoints-min(sm) {
      margin: 0.5%;
      width: 32.2%;
    }
    @include breakpoints-min(md) {
      width: 100%;
      margin: 0;
      + .ec-sideBanner__item {
        margin: 5px 0 0;
      }
      .ec-productRole__banner-area & {
        width: 32.2%;
        margin: 0.5%;
        + .ec-sideBanner__item {
          margin: 0.5%;
        }
      }
    }
    @include breakpoints-min(lg) {
      .ec-productRole__banner-area & {
        width: 24%;
      }
    }
    a {
      &:hover {
        img {
          opacity: $opacity-default;
        }
      }
    }
    img {
      width: 100%;
      transition: $transition-default;
    }
  }
  .ec-content__frame__inner {
    background-color: $bg-sub-content;
  }
}