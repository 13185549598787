.display-only {
  &__pc {
    @include breakpoints-max(md) {
      display: none !important;
    }
  }
  &__pc-tb {
    @include breakpoints-max(sm) {
      display: none !important;
    }
  }
  &__tb {
    @include breakpoints-min(md) {
      display: none !important;
    }
    @include breakpoints-max(sm) {
      display: none !important;
    }
  }
  &__tb-sp {
    @include breakpoints-min(md) {
      display: none !important;
    }
  }
  &__sp {
    @include breakpoints-min(sm) {
      display: none !important;
    }
  }
}


