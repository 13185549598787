@mixin breakpoints-min($size) {
  @media (min-width: (map-get($breakpoints, $size))) {
    @content;
  }
}

@mixin breakpoints-max($size) {
  @media (max-width: (map-get($breakpoints, $size)) - 1) {
    @content;
  }
}

@mixin breakpoints($size-min, $size-max) {
  @media (min-width: (map-get($breakpoints, $size-min))) and (max-width: (map-get($breakpoints, $size-max)) - 1) {
    @content;
  }
}
