@import "../mixins/media";

/*
リスト

シンプルなリストを構成するためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.3
*/

/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　水平定義リスト部分](http://demo3.ec-cube.net/help/about)

Markup:
dl.ec-definitions
    dt 店名
    dd EC-CUBE3 DEMO SHOP
dl.ec-definitions
    dt 会社名
    dd EC-CUBE3
dl.ec-definitions--soft
    dt 所在地
    dd 〒 550-0001

Styleguide 1.3.1
*/
.ec-definitions {
  margin: 5px 0;
  display: block;
  & dt, dd {
    display: inline-block;
    margin: 0;
  }
  & dt {
    font-weight: bold;
  }
}

.ec-definitions--soft {
  @extend .ec-definitions;
  & dt {
    font-weight: normal;
  }
}

/*
下線つき定義リスト

線が添えられた定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　下線つき定義リスト](http://demo3.ec-cube.net/help/about)

Markup:
.ec-borderedDefs
  dl
    dt 店名
    dd EC-CUBE3 DEMO SHOP
  dl
    dt 会社名
    dd EC-CUBE3
  dl
    dt 所在地
    dd 〒550 - 0001

Styleguide 1.3.2
*/

.ec-borderedDefs {
  width: 100%;
  margin-bottom:16px;
  dl {
    display: flex;
    border-bottom: 1px dotted #ccc;
    margin: 0;
    padding: 8px 0 3px;
    flex-wrap: wrap;
    @include media_desktop {
      flex-wrap: nowrap;
    }
  }
  dt, dd {
    padding: 0;
  }

  dt {
    font-weight: normal;
    width: 100%;
    padding-top: 0;
    @include media_desktop {
      padding-top: 5px;
      width: 30%;
    }
    .en-notation {
      font-size: $font-size-pc-low2;
      @include media_desktop {
        display: inline-block;
      }
    }
  }

  dd {
    padding: 0;
    width: 100%;
    line-height: 2.5;
    @include media_desktop {
      width: 70%;
      //padding: 18px 16px;
      line-height: 3;
    }
  }
  p {
    line-height: 1.4;
  }
}

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0;

  dt, dd {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    padding: 0;
    @include media_desktop {
      padding: 16px 0;
    }
  }

  dt {
    width: 30%;
  }

  dd {
    padding: 0;
    @include media_desktop {
      padding: 16px;
    }
  }
}

/*
ボーダーリスト

線が添えられたリストを表示します。

ex [当サイトについて　ボーダーリスト](http://demo3.ec-cube.net/help/about)

Markup:
ul.ec-borderedList
  li: p lorem
  li: p lorem
  li: p lorem


Styleguide 1.3.3
*/

.ec-borderedList {
  width: 100%;
  border-top: 0;
  list-style: none;
  padding: 0;
  @include media_desktop {
    border-top: 1px dotted #ccc;
  }
  li {
    border-bottom: 1px dotted #ccc;
  }
}

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0;

  dt, dd {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    padding: 16px 0;
  }

  dt {
    width: 30%;
  }

  dd {
    padding: 16px;
  }
}
