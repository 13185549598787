.iframe-wrap {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    border: none;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        display: block;
    }
}

