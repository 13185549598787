@import "../mixins/media";
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole{
  @include container;
  margin-bottom: 24px;
  ul{
    padding: 0;
    list-style: none;
  }
}
.ec-sliderItemRole{
  @include container;
  margin-bottom: 24px;
  position: relative;
  @include breakpoints-max(md) {
    > div {
      padding-right: 0;
      padding-left: 0;
    }
  }
  ul{
    padding: 0;
    list-style: none;
  }
  .item_visual{
    width: 100%;
    &__area {
      margin-bottom: 20px;
      @include  breakpoints-max(md){
        width: 79.1666%;
      }
    }
  }
  .item_nav {
    height: 100%;
    //display: none;
    @include  breakpoints-min(md) {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 0;
    }
    &__area {
      z-index: 1;
      height: 100%;
      @include  breakpoints-max(md) {
        position: absolute;
        top: 0;
        right: 0;
        width: 20%;
        padding: 0;
        overflow-y: scroll;
        &::-webkit-scrollbar { /* スクロールバー全体 */
          width: 5px;
        }
        &::-webkit-scrollbar-thumb{ /* スクロールバーのある部分 */
          background: $btn-cancel-bg;
        }
        &::-webkit-scrollbar-track-piece:start{ /* スクロールバーが表示されてない部分（前） */
          background: $border-gray-default;
        }
        &::-webkit-scrollbar-track-piece:end{ /* スクロールバーが表示されてない部分（後ろ） */
          background: $border-gray-default;
        }
      }
    }
    &__arrow {
      .slick-arrow{
        position: absolute;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 20px;
        background-color: $bg-sub-content;
        &.slick-disabled {
          display: none !important;
        }
        &:before {
          display: block;
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
        }
      }
      .slick-prev{
        top: 10px;
        &:before {
          content: "▲";
        }
      }
      .slick-next{
        top: auto;
        bottom: -10px;
        &:before {
          content: "▼";
        }
      }
    }
    .slick-list {
      height: 100% !important;
    }
  }
  .slideThumb{
    margin-bottom: 5px;
    width: 32% !important;
    opacity: .8;
    cursor: pointer;
    + .slideThumb {
      @include  breakpoints-min(md) {
        margin-left: 2%;
      }
    }
    &:nth-child(3n+1) {
      margin-left: 0 !important;
    }
    @include  breakpoints-max(md) {
      width: 100% !important;
      margin-bottom: 5px;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 1;
    }
    img {
      width: 80%;
      @include  breakpoints-min(md) {
        width: 100%;
      }
    }
  }
}
