.ec-relatedProduct {
  margin-bottom: 15px;
  &__itemlist {
    margin: 0;
    padding: 0;
  }
  &__item {
    list-style: none;
    + .ec-relatedProduct__item {
      margin-top: 5px;
    }
    a {
      display: flex;
      transition: $transition-default;
      &:hover {
        opacity: $opacity-default;
      }
    }
  }
  &__img {
    width: 30%;
    margin-right: 2%;
    @include breakpoints-min(md) {
      width: 40%;
    }
    img {
      width: 100%;
    }
  }
  &__detail {
    width: 68%;
    @include breakpoints-min(md) {
      width: 58%;
    }
  }
  &__name {

  }
  &__description {
  }
  .ec-content__frame__inner {
    background-color: $bg-sub-content;
  }
}