@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole{
  margin-top: 15px;

  @include media_desktop(){
    @include media_desktop {
      @include container;
      margin-top: 15px !important;
    }
  }
  & &__inner{
    padding-top: 0;
    @include media_desktop {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi{
  padding: 0;
  color: white;
  list-style: none;
  @include clearfix;

  & &__link {
    display: block;
    float: left;
    width: 50%;
    border-bottom: 1px solid #2e2e2e;

    &:nth-child(2n+1) {
      @include breakpoints-max(md) {
        a {
          padding-left: 18px;
        }
      }
    }

    &:nth-child(2n) {
      @include breakpoints-max(md) {
        a {
          padding-right: 18px;
        }
      }
    }


    @include media_desktop {
      width: 25%;
      border-bottom: none;

    }
    @include breakpoints-max(md) {
      background-color: #373737;
    }

    a{
      padding: 10px 0;
      font-size: 1.2rem;
      color: #c9c9c9;
      text-decoration: none;
      display: block;
      border-bottom: none;
      @include hover__text-underline(span,#ffffff);
      @include breakpoints-min(md) {
        text-align: center;
        padding: 15px 0;
        font-size: 1.3rem;
      }
    }

  }
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle{
  padding: 20px 0 0;
  text-align: center;
  color: white;

  @include breakpoints-max(md) {
    padding: 0 0 60px;
  }

  & &__logo{
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @include reset_link();

    a{
      font-size: 18px;
      color: inherit;
      &:hover, &:active, &:focus {
        &:after {
          background: #c9c9c9;
          opacity: 1;
          visibility: visible;
        }
      }
      @include media_desktop {
        font-size: 18px;
      }

    }

    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }
    }
  }
  & &__copyright{
    font-size: 10px;

    @include media_desktop {
      font-size: 12px;
    }
  }
  & &__catchphrase {
    font-size: 1rem;
  }
}
