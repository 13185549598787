.ec-checkTogetherRole {
  margin-bottom: 15px;
  padding: 0 !important;
  &__itemlist {
    margin: 0;
    padding: 0;
  }
  &__item {
    list-style: none;
    + .ec-relatedProduct__item {
      margin-top: 5px;
    }
    a {
      display: flex;
      transition: $transition-default;
      &:hover {
        opacity: $opacity-default;
      }
    }
  }
  &__img {
    width: 30%;
    margin-right: 2%;
    @include breakpoints-min(md) {
      width: 40%;
    }
    img {
      width: 100%;
    }
  }
  &__detail {
    width: 68%;
    @include breakpoints-min(md) {
      width: 58%;
    }
  }
  &__name {

  }
  &__description {
  }
  &__listItemTitle {
    margin-top: 0 !important;
  }
  .ec-content__frame__inner {
    background-color: $bg-sub-content;
  }
  .slick-slider {
    margin-bottom: 0 !important;
  }
  .slick-dots {
    position: initial !important;
    margin: 0;
    li {
      width: 10px !important;
      height: 10px !important;
    }
    button {
      width: 10px !important;
      height: 10px !important;
      &:before {
        border-radius: 0 !important;
        width: 6px !important;
        height: 6px !important;

      }
    }
  }
  .ec-secHeading {
    @include breakpoints-min(md) {
      margin-top: 15px;
    }
    &__en {
      @include breakpoints-max(md) {
        letter-spacing: normal;
        font-size: 1.7rem;
      }
    }
  }
}