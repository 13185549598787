.ec-content {
  &__title-img {
    margin: 0;
    line-height: 0;
    padding-bottom: 5px;
    .ec-headerLogin &,
    .ec-headerTopics & {
      @include breakpoints-min(md) {
        height: 25px;
        img {
          height: 100%;
        }
      }
    }
  }
  &__frame {
    @include clearfix;
    .ec-headerLogin &, .ec-headerTopics & {
      display: flex;
      width: 100%;
      height: calc(100% - 25px);
    }
    &__inner {
      @include clearfix;
      padding: 10px;
      .ec-relatedProduct, .ec-productTracklist {
        padding: 5px;
      }
      .ec-headerLogin &, .ec-headerTopics & {
        background-color: $bg-sub-content;
        width: 100%;
      }
      .mainContents & {
        //user_data
        background-color: #ffffff;
      }
      .tplO & {
        //user_data
        background-color: $bg-other-content;

      }
    }
  }

}



