.ec-topRecordsRole {
  .ec-content__frame__inner {
    background-color: rgba(255,255,255,0.8);
  }
  &__itemlist {
    padding: 0;
  }
  &__item {
    width: 24%;
    list-style: none;
    float: left;
    margin: 0.5%;
    @include breakpoints-min(md) {
      width: 15.66%;
    }
    @include breakpoints-min(sm) {
      width: 19%;
    }
    //&:nth-child(10n+1) {
    //  //1,6right,19
    //  width: 48.9%;
    //  clear: both;
    //}
    //&:nth-child(10n+6) {
    //  //1,6right,19
    //  width: 48.9%;
    //  float: right;
    //}
    //&:nth-child(12n+1) {
    //  //1,6right,19
    //  width: 32.2%;
    //  clear: both;
    //}
    //&:nth-child(12n+8) {
    //  //1,6right,19
    //  width: 32.33%;
    //  float: right;
    //}
    //&:nth-child(12n+9), &:nth-child(12n+10), &:nth-child(12n+11) {
    //  //1,6right,19
    //  float: right;
    //}
    img {
      width: 100%;
    }
  }
}