@import "../mixins/media";
@import "../mixins/projects";
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole{
  $e: &;
  @include container;
  word-break: break-word;
  &--title, &--image {
    @extend #{$e};
  }
  .ec-content__frame__inner {
    background-color: $bg-sub-content;
    @include breakpoints-max(md) {
      padding: 0;
      background-color: transparent;
    }
  }
  &__tags {
    padding: 0 !important;
  }
  &__tag {
    border: none !important;
    margin: 2px 0;
    &:last-child {
      margin-bottom: 10px;
    }
  }
}

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid{
  @include reset_link;
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;

  //@include media_desktop {
  //  margin-left: -16px;
  //  margin-right: -16px;
  //}
  & &__item{
    margin-bottom: 4px;
    width: 50%;
    .ec-shelfRole--title & {
      width: 100%;
      margin-bottom: 10px;
    }
    .ec-shelfRole--image & {
      width: 50%;
      padding-left: 2px;
      padding-right: 2px;
      @include media_desktop(){
        width: 25%;
      }
    }
    &-image {
      text-align: center;
      position: relative;
      .ec-shelfRole--title & {
        margin-bottom: 5px;
      }
    }
    img{
      width: auto;
      max-height: 100%;
    }
    //@include breakpoints-min(md){
    //  padding: 0 16px;
    //}

    .ec-productRole__btn {
      margin-top: auto;
    }
    &__inner {
      display: flex;
      padding: 10px;
      background-color: $bg-main-content;
      height: 100%;
      .ec-shelfRole--image & {
        flex-direction: column;
      }
    }
  }
  //& &__item:nth-child(odd){
  //  padding-right: 8px;
  //  @include media_desktop(){
  //    padding: 0 16px;
  //  }
  //}
  //& &__item:nth-child(even){
  //  padding-left: 8px;
  //  @include media_desktop(){
  //    padding: 0 16px;
  //  }
  //}
  & &__title {
    margin-bottom: 7px;
  }
  &__detail-area {
    display: flex;
    flex-direction: column;
    .ec-shelfRole--title & {
      flex: 1;
      @include breakpoints-min(md) {
        flex: none;
        width: 70%;
      }
    }
    .ec-shelfRole--image & {
      margin-top: auto;
    }
  }
  &__image-area {
    .ec-shelfRole--title & {
      width: 122px;
      margin-right: 10px;
      @include breakpoints-min(md) {
        width: 30%;
      }
    }
  }
  .ec-price {
    text-align: right;
    margin: 5px 0;
  }
}

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/
.ec-shelfGridCenter{
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center;

  @include media_desktop {
    margin-left: -16px;
    margin-right: -16px;
  }
  & &__item{
    margin-bottom: 36px;
    width: 50%;
    &-image {
      height: 150px;
      margin-bottom: 10px;
      text-align: center;
      @include media_desktop() {
        height: 250px;
      }
    }
    img{
      width: auto;
      max-height: 100%;
    }
    @include media_desktop(){
      padding: 0 16px;
      width: 25%;
    }

    .ec-productRole__btn {
      margin-top: auto;
      padding-top: 1em;
    }
  }
  & &__item:nth-child(odd){
    padding-right: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__item:nth-child(even){
    padding-left: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
}
