
// MediaQuery
$tablet: 480px;
$desktop: 768px;
$desktop2: 992px;
$desktop3: 1200px;

$clrRed: #DE5D50;
$clrRich: #9a947e;
$clrGray: #F3F3F3;
$clrRightGray: #B8BEC4;
$clrExtraGray: #636378;
$clrDarkGray:#525263;
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";

$breakpoints: (
        xs: 0,
        sm: 544px,
        md: 768px,
        lg: 992px,
        xl: 1130px,
);

$transition-default: .3s;
$opacity-default: 0.8;
$border-radius-default: 2px;

$border-gray-default: #dadad8;

$container-max-width: 1130px;

$font-color: #555555;
$font-link-color: #FB7200;
$warning-color: #ff5c00;
$bg-color-dark: #373737;

$bg-main-content: rgba(255, 255, 255, 0.8);
$bg-sub-content: rgba(255, 255, 255, 0.5);
$bg-other-content: rgba(230,230,230,0.6);

$font-size-pc: 14px;
$font-size-pc-add2: $font-size-pc + 2px;
$font-size-pc-low2: $font-size-pc - 2px;

$font-size-sp: 16px;
$font-size-sp-add2: $font-size-sp + 2px;
$font-size-sp-low2: $font-size-sp - 2px;