.audio-area {
  audio {
    display: none;
  }
  .contr,
  .seekbar {
      display: none;
  }
}

.mejs__currenttime-container,
.mejs__duration-container,
.mejs__volume-button,
.mejs__horizontal-volume-slider {
  display: none !important;
}

.mejs__overlay-button {
  background-image: url("../img/plugin/mediaelementjs/mejs-controls.svg") !important;
}
.mejs__overlay-loading-bg-img {
  background-image: url("../img/plugin/mediaelementjs/mejs-controls.svg") !important;
}
.mejs__button > button {
  background-image: url("../img/plugin/mediaelementjs/mejs-controls.svg") !important;
}

