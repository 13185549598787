@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/clearfix";
@import "../mixins/animation";

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s;
  background: url(/html/user_data/packages/default/img/customize/background.png) 0 3px repeat;
  & &__contentTop {
    padding: 0;
  }
  & &__earth {
    margin: 0 auto;
    width: 100%;
    max-width: 1330px;
    background: url(/html/user_data/packages/default/img/customize/bg_logo.png) no-repeat scroll 0 0 transparent;
    @include breakpoints-min(md) {
      padding-bottom: 15px;
    }
  }
  & &__contents {
    margin: 20px auto 15px;
    width: 100%;
    max-width: $container-max-width;
    display: flex;
    flex-wrap: wrap;
    @include breakpoints-min(md) {
      margin-bottom: 40px;
    }
  }
  & &__category {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
    @include breakpoints-min(md) {
      margin-top: 0;
    }
  }
  & &__full-bg {
    width: 100%;
    background-color: $bg-color-dark;
  }
  & &__full-border-top {
    width: 100%;
    border-top: 10px solid $bg-color-dark;
    @include breakpoints-max(md) {
      border-top: 5px solid $bg-color-dark;
    }
  }
  & &__main {
    width: 100%;
    .ec-layoutRole__contents {
      //detail.twigのみに使用
      margin-bottom: 0;
    }
  }
  & &__mainWithColumn {
    width: 100%;
    @include media_desktop() {
      width: calc(100% - 18px - 225px);
    }
  }
  & &__mainBetweenColumn {
    width: 100%;
    @include media_desktop() {
      width: 50%;
    }
  }
  & &__left,
  & &__right {
    //display: none;
    display: block;
    width: 100%;
    @include breakpoints-max(md) {
      padding-right: 8px;
      padding-left: 8px;
    }
    @include media_desktop() {
      width: 225px;
    }
    > div {
      + div {
        margin-top: 10px;
      }
    }
  }
  & &__right {
    margin-top: 40px;
    #page_product_detail & {
      margin-top: 0;
    }
    @include breakpoints-min(md) {
      margin-top: 0;
      padding-left: 10px;
    }
    .ec-headerLogin {
      @include breakpoints-max(md) {
        display: none;
        + div {
          margin-top: 0;
        }
      }
    }
  }
  & &__header {
  }
  & &__footer {
  }
}


.ec-headerRole {
  $e: &;
  @include container;
  padding-top: 10px;
  position: relative;
  margin-bottom: 15px;
  &:after {
    display: none;
  }
  @include media_desktop {
    @include clearfix;
  }
  &::before {
    display: none;
  }
  &--topics {
    @extend #{$e};
    @include breakpoints-min(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: auto;
    }
  }
  @include media_desktop {
    width: 100%;
    @include clearfix;
  }
  & &__title {
    width: 100%;
  }
  & &__navSP {
    display: block;
    position: absolute;
    top: 15px;
    width: 27%;
    right: 0;
    text-align: right;
    @include media_desktop {
      display: none;
    }
  }
}

.ec-headerNaviRole {
  @include container;
  padding: 0!important;
  &:after {
    @include breakpoints-min(lg) {
      content: none !important;
    }
  }
  .fa-bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  //& &__left {
  //  width: calc(100% / 3);
  //
  //}

  &__search {
    //display: none;
    //@include media_desktop() {
    @include clearfix;
      display: block;
      margin-top: 10px;
      @include reset_link;
    //}
  }
  & &__navSP {
    display: block;
    @include media_desktop() {
      display: none;
      @include reset_link;
    }
  }

  //& &__right {
  //  width: calc(100% * 2 / 3);
  //  display: flex;
  //  justify-content: flex-end;
  //  align-items: center;
  //}

  & &__nav {
    display: inline-block;
    @include reset_link;
  }
  & &__cart {
    display: inline-block;
    @include reset_link;
  }

  &__category-slide  {
    ul {
      width: 100%;
      overflow: auto;
      padding: 20px 0 10px;
      margin: 0;
      white-space:nowrap;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      li {
        display: inline-block;
        padding-right: 10px;
        a {
          color: $font-color;
          font-weight: bold;
        }
      }
    }
  }
}

.ec-headerNavSP {
  display: block;
  cursor: pointer;
  //display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;

  .fas {
    vertical-align: top;
  }

  @include media_desktop {
    display: none;
  }
}
.ec-headerNavSP.is-active {
  display: none;
}

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
  @include commonStyle();
  padding-top: 5px;
  @include breakpoints-min(md) {
      width: 300px;
    float: left;
  }
  & &__title {
    h1 {
      margin: 0 0 5px 0;
      padding: 0;
      text-align: center;
      @include breakpoints-min(md) {
        text-align: left;
      }
    }
    a {
      display: inline-block;
      text-decoration: none;
      font-size: 1.3rem;
      text-align: center;
      transition: $transition-default;

      @include breakpoints-min(md) {
        font-size: 1.3rem;
      }
      color: black;

      &:hover {
        opacity: .8;
      }
      img {
        display: block;
        margin: 0 0 5px;
        @include breakpoints-max(md) {
          margin: 0 auto 5px;
          max-width: 130px;
        }
      }
      span {
        @include breakpoints-max(md) {
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }
  }
  & &__subtitle {
    font-size: 10px;
    text-align: center;
    @include media_desktop() {
      font-size: 16px;
      margin-bottom: 10px;
    }
    a {
      display: inline-block;
      color: $font-link-color;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNav {
  $e: &;
  @include clearfix;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include breakpoints-min(md) {
    display: block;
    float: right;
    width: calc(100% - 300px);
    padding-bottom: 10px;
  }
  & &__item {
    margin-left: 0;
    display: inline-block;
    a {
      display: flex;
      justify-content: space-around;
      text-align: center;
      text-decoration: none;
      margin: 5px 0;
      @include hover__text-underline(span,#000000);
      @include breakpoints-min(md) {
        margin: 0;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    @include breakpoints-max(md) {
      width: 33.3%;
    }
    @include breakpoints-min(md) {
      float: right;
      + .ec-headerNav__item {
        margin-left: 20px;
      }
      &:last-child {
        margin: 0;
      }
    }
    &--help {
      @extend #{$e}__item;
      display: none;
      @include breakpoints-min(md) {
        display: block;
        margin-left: 20px;
      }
      .ec-headerNav__itemIcon {
        width: 9px;
      }
    }

  }
  & &__itemIcon {
    display: none;
    font-size: 10px;
    color: black;
    @include media_desktop {
      display: inline-block;
    }
  }
  & &__itemLink {
    //display: none;
    font-size: 1.2rem;
    vertical-align: middle;
    color: black;
    padding: 5px 10px;
    display: inline-block;
    @include breakpoints-min(md) {
      padding: 0 0 3px 0;
      line-height: 1;
      height: 1.2em;
    }
  }
}

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch{
  @include clearfix;
  width: 100%;
  @include breakpoints-min(lg) {
    padding-bottom: 10px;
    width: calc(100% - 300px);
    display: inline-block;
  }
  .searchform {

    @include breakpoints-min(lg) {
      float: right;
      width: 82%;
    }
  }
  & &__category {
    float: left;
    width: 35%;

    @include media_desktop {
      float: left;
    }
    .ec-select, .ui-selectmenu-button {
      overflow: hidden;
      width: 100%;
      margin: 0;
      height: 34px;
      border-radius: 0;
    }
    .ec-select .ec-select__inline {
      display: block;
      &:before {
        border-top-color: #ffffff;
        top: 45%;
      }
    }
    .ui-selectmenu-button {
      .ui-selectmenu-icon {
        position: absolute;
        display: block;
        top: 45%;
        right: 10px;
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #ffffff;
        pointer-events: none;
        background-image: none;
      }
    }
    .ec-select select, .ui-selectmenu-button {
      border-color: transparent;
      background: $bg-color-dark;
      color: #fff;
      border-radius: 5px 0 0 5px;
      @include breakpoints-min(md) {
        margin-bottom: 0;
        padding: 8px 24px 8px 8px;
        font-size: $font-size-pc-low2;
      }

      .ui-selectmenu-text {
        font-size: $font-size-sp-add2;
        line-height: 1.4;
        @include breakpoints-min(md) {
          font-size: $font-size-pc-low2;
        }
      }

      option {
        color: #000;
      }

      &::-ms-expand {
        display: none;
      }
    }

  }
  & &__keyword{
    position: relative;
    width: 65%;
    float: right;

    @include media_desktop {
      float: right;
    }
    input[type="search"]{
      width: 100%;
      height: 34px;
      font-size: $font-size-sp;
      border: 1px solid transparent;
      border-radius: 0;
      box-shadow: none;
      background: none;
      box-sizing: border-box;
      margin-bottom: 0;
      background-color: #ffffff;
      @include media_desktop {
        font-size: $font-size-pc-low2;
      }
    }
    .ec-icon {
      width: 22px;
      height: 22px;
    }
    .ec-input {
      padding-right: 48px;
    }
  }
  & &__keywordBtn{
    border: 1px solid transparent;
    border-left: none;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    white-space: nowrap;
    z-index: 1;
    padding: 7px;
    font-size: 1.2rem;
    color: #ffffff;
    font-weight: bold;
    width: 48px;
    height: 34px;
    background-color: $warning-color;
    border-radius: 0 5px 5px 0;
  }
}

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole {
  //display: none;
  @include media_desktop() {
    @include container;
    margin-bottom: 15px;
    display: block;
    width: 100%;
    @include reset_link;
  }
  .ec-content__frame__inner {
    padding: 0;
  }
  .ec-content__title-img {
    @include breakpoints-min(md) {
      display: none;
    }
    @include breakpoints-max(md){
      padding-right: 8px;
      padding-left: 8px;
    }
  }
}

.ec-itemNav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.ec-itemNav__nav {
  //display: block;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: auto;
  list-style-type: none;
  text-align: center;
  vertical-align: bottom;
  display: flex;
  flex-wrap: wrap;
  background-color: $bg-color-dark;
  @include breakpoints-min(md) {
    background-color: transparent;
  }
  li {
    display: flex;
    align-items: center;
    @include breakpoints-min(md) {
      &:hover {
        ul {
          li {
            ul {
              li {
                a:hover {
                  background: #000000;
                }
              }
            }
          }
        }
        > ul > li > ul {
          &:before {
            @include media_desktop {
              content: "\f054";
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              font-size: 12px;
              color: white;
              position: absolute;
              top: 12px;
              left: -20px;
            }
          }
        }
      }
    }
  }
  > li {
    //float: none;
    padding: 0;
    width: 50%;
    text-align: center;
    position: relative;
    @include breakpoints-min(md) {
      width: 20%;
    }

    &:nth-child(2n+1) {
      @include breakpoints-max(md) {
        a {
          padding-left: 18px;
        }
      }
    }

    &:nth-child(2n) {
      @include breakpoints-max(md) {
        a {
          padding-right: 18px;
        }
      }
    }

    &:hover {
      @include breakpoints-min(md) {
        > a {
          background: #000000;
          color: #fff;
          border-bottom: 1px solid $bg-color-dark;
        }
        li {
          &:hover {
            > a {
              background: #000000;
            }
          }
        }
        ul {
          @include breakpoints-min(md){
            z-index: 100;
          }
        }
        > ul {
          > li {
            @include media_desktop {
              overflow: visible;
              height: auto;

            }
          }
          &:before {
            color: #000000;
          }
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 10px 0;
      color: #2e3233;
      font-size: $font-size-pc;
      line-height: 1.2;
      text-decoration: none;
      text-align: left;
      background: #fff;
      word-break: break-all;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid transparent;
      @include breakpoints-min(md) {
        padding: 10px 22px 10px 10px;
      }
    }
    > a {
      background-color: $bg-color-dark;
      color: #fff;
      border-color: #303030;
      @include breakpoints-min(md) {
        border-color: transparent;
      }
    }
    &:nth-child(-n+5) {
      > a {
        @include breakpoints-min(md) {
          border-color: #303030;
        }
      }
    }
    ul {
      display: none;
      z-index: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      list-style: none;
      position: static;
      top: 100%;
      left: 0;
      @include media_desktop {
        display: block;
        position: absolute;
      }
      li {
        ul {
          top: 0;
          left: 100%;
          width: 100%;
          li {
            a {
              background: #5a5a5a;
            }
          }
        }
        @include breakpoints-min(md) {
          &:hover  {
            > ul {
              > li {
                overflow: visible;
                height: auto;
                width: auto;
              }
            }
          }
        }
      }
    }
    > ul {
      &:before {
        @include media_desktop {
          content: "\f054";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 12px;
          color: $bg-color-dark;
          position: absolute;
          top: -26px;
          right: 10px;
        }
      }
      li {
        overflow: hidden;
        width: 100%;
        height: auto;
        transition: .3s;
        @include media_desktop {
          overflow: hidden;
          height: 0;
        }
      }
      > li {
        a {
          border-bottom: 1px solid $bg-color-dark;
          padding: 10px 22px 10px 10px;
          font-size: $font-size-pc;
          color: white;
          text-align: center;
          background: #5a5a5a;
        }
      }
    }
  }
}

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/

.ec-drawerRole {
  overflow-y: scroll;
  background: black;
  width: 260px;
  height: 100vh;
  transform: translateX(-300px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: z-index 0ms 1ms;
  @include media_desktop() {
    display: none;
  }


  .ec-headerSearchArea {
    padding: 20px 10px;
    width: 100%;
    background: #F8F8F8;
  }

  .ec-headerSearch{
    padding: 16px 8px 26px;
    background: #EBEBEB;
    color: #636378;
    select{
      width: 100% !important;
    }
  }

  .ec-headerCategoryArea{
    .ec-headerCategoryArea__heading {
      border-top: 1px solid #CCCCCC;
      border-bottom: 1px solid #CCCCCC;
      padding: 1em 10px;
      font-size: 16px;
      font-weight: bold;
      color: black;
      background: #F8F8F8;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .ec-itemNav__nav li a {
      border-bottom: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      color: black;
      font-weight: normal;
      background: #f8f8f8;
    }

    .ec-itemNav__nav li ul li a {
      border-bottom: 1px solid #ccc;
      padding-left: 20px;
      font-weight: normal;
      background: white;
    }

    .ec-itemNav__nav > li:hover > a {
      background: #f8f8f8;
    }

    .ec-itemNav__nav > li:hover li:hover > a {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li a {
      padding-left: 40px;
      color: black;
      background: white;
    }

    .ec-itemNav__nav li:hover ul li ul li a:hover {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li ul li a{
      padding-left: 60px;
      font-weight: normal;
    }
  }
  .ec-headerLinkArea {
    background: black;

    .ec-headerLink__list {
      border-top: 1px solid #ccc;

    }

    .ec-headerLink__item {
      display: block;
      border-bottom: 1px solid #ccc;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: bold;
      color: white;
    }
    .ec-headerLink__icon {
      display: inline-block;
      width: 28px;
      font-size: 17px;
    }



  }

}

.ec-drawerRoleClose {
  display: none;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 270px;
  z-index: 1000;

  .fas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include media_desktop {
    display: none;
  }

}

.ec-drawerRole.is_active {
  display: block;
  transform: translateX(0);
  transition: all .3s;
  z-index: 100000;

  @include media_desktop() {
    display: none;
  }
}
.ec-drawerRoleClose.is_active  {
  display: inline-block;
  transition: all .3s;

  @include media_desktop {
    display: none;
  }
}

.ec-overlayRole {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all .3s;
  visibility: hidden;

  @include media_desktop {
    display: none;
  }
}

.have_curtain .ec-overlayRole {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible;

  @include media_desktop {
    display: none;
  }
}

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/

.ec-itemNavAccordion {
  display: none;
}

.ec-headerLogin {
  width: 100%;
  margin-bottom: 15px;
  .ec-layoutRole__header & {
    @include breakpoints-min(md) {
      width: calc((100% - 18px)/3 * 1);
      margin-bottom: 0;
    }
  }
  #page_mypage_login &, #page_shopping_login & {
    display: none;
  }
  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  &__message {
    display: flex;
    flex-flow: column;
    height: 100%;
    &__heading {
      font-size: 1.3rem;
      margin-bottom: 5px;
    }
    &__body {
      padding: 10px;
      background-color: #ebebeb;
      height: 100%;
      p {
        font-size: 13px;
      }

    }
  }
  .ec-login {
    &__input {
      .ec-layoutRole__header & {
        @include breakpoints-min(md) {
          width: calc(100% - 70px - 10px);
          padding-right: 10px;
        }
      }
      .ec-input input {
        @include breakpoints-min(md) {
          font-size: $font-size-pc-low2;
          padding: 3px 5px;
          &[type="password"] {
            margin-bottom: 10px;
            .ec-headerRole & {
              margin-bottom: 0;
            }
          }
        }
      }
      .ec-checkbox {
        height: 0;
      }
    }
    &__actions {
      width: 100%;
      .ec-layoutRole__header & {
        @include breakpoints-min(md) {
          width: 70px;
          button {
            height: 100%;
          }
        }
      }
    }
    &__link {
      text-align: right;
      font-size: 1.2rem;
      margin-bottom: 5px;
    }
  }
  .ec-checkbox {
    margin: 0;
  }
  .ec-blockBtn--action {
    margin-top: 10px;
    border-radius: $border-radius-default;
  }
  button {
    border-radius: $border-radius-default;
  }

  &__recommend-record {
    margin-top: 5px;
    padding: 8px;
    background: #fff;

    &__title {
      font-weight: bold;
      text-align: center;
      font-size: 13px;
      color: $font-color;
      margin-bottom: 15px;
      padding: 2px 2px 4px;
      border-bottom: 1px solid $font-color;
      i {
        padding-right: 10px;
      }
    }

    &__image-area {
      display: flex;
      font-size: 12px;

      .ec-headerRole--topics & {
        font-size: 14px;
      }

      .product-image {
        width: 36%;
        img {
          width: 100%;
        }
      }

      .product-name {
        width: 62%;
        margin-left: 2%;
        display: flex;
        flex-direction: column;
        .audio-area {
          margin-top: auto;
          .time {
            .ec-layoutRole__right & {
              display: none;
            }
          }

        }
      }

    }
  }
  .ec-errorMessage {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #DE5D50;
  }
}

.ec-headerTopics {
  width: 100%;
  margin-bottom: 15px;
  @include breakpoints-min(md) {
    width: calc((100% - 18px)/3 * 2);
    margin-left: 18px;
    margin-bottom: 0;
  }
  .ec-content__frame__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & &__itemlist {
    overflow-y: scroll;
    width: 100%;
    margin-bottom: 15px;
    max-height: 200px;
    padding-right: 10px;
    @include breakpoints-min(md) {
      width: calc(100% - 195px - 18px);
      margin-right: 18px;
      margin-bottom: 0;
    }
    &::-webkit-scrollbar { /* スクロールバー全体 */
      width: 5px;
    }
    &::-webkit-scrollbar-thumb{ /* スクロールバーのある部分 */
       background: $btn-cancel-bg;
     }
    &::-webkit-scrollbar-track-piece:start{ /* スクロールバーが表示されてない部分（前） */
      background: $border-gray-default;
    }
    &::-webkit-scrollbar-track-piece:end{ /* スクロールバーが表示されてない部分（後ろ） */
      background: $border-gray-default;
    }
  }
  & &__item {
    width: 100%;
    + .ec-headerTopics__item {
      margin-top: 10px;
    }
  }
  & &__newsHeading {

  }
  & &__newsDate {
    display: inline-block;
    margin: 0;
    min-width: 120px;
    font-size: 14px;
    color: $warning-color;

    @include media_desktop {
    }

  }
  & &__newsColumn {
    display: flex;

    @include media_desktop {
      display: inline-flex;
      min-width: calc(100% - 150px);
    }
  }

  & &__newsTitle {
    display: block;
    font-size: 14px;
    color: #000;
  }
  & &__newsClose {
    display: inline-block;
    width: 30px;
    margin: 0 0 5px 0;
  }
  & &__newsCloseBtn {
    display: inline-block;
    margin-left: auto;
    width: 20px;
    height: 20px;
    color: white;
    text-align: center;
    background: $border-gray-default;
    cursor: pointer;
    .fa-angle-down {
      transform: rotate(270deg);
    }
  }
  & &__newsDescription {
    margin: 5px 0 0;
    padding: 10px;
    font-size: 14px;
    overflow: hidden;
    background-color: #ebebeb;

    a {
      color: $font-link-color;
    }
  }
  &__newsItem.is_active &__newsDescription{
    margin: 0 0 10px;

    @include media_desktop {
      margin: 20px 0 0;
    }
  }
  &__newsItem.is_active &__newsCloseBtn i {
    display: inline-block;
    transform: rotateX(180deg) translateY(2px);
  }
  & &__newsUrl {
    @include media_desktop {
      margin: 5px 0 0;
      padding: 10px;
      font-size: 14px;
      overflow: hidden;
      background-color: #ebebeb;
    }

    a {
      display: block;
      margin: 5px 0 0;
      padding: 10px;
      font-size: 14px;
      overflow: hidden;
      background-color: #FB7200;
      color: #fff;
      text-align: center;
      text-decoration: none;
      @include media_desktop {
        display: inline;
        margin: 0;
        padding: 0;
        background: none;
        color: $font-link-color;
        text-align: left;
        text-decoration: underline;
      }
    }
  }
}


.ec-headerBanner {
  //width: 100%;
  //margin-bottom: 15px;
  //@include breakpoints-min(md) {
  //  width: 225px;
  //  margin-bottom: 0;
  //}
  &__itemlist {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include breakpoints-min(md) {
      display: block;
      width: 195px;
    }
  }
  &__item {
    width: 32.2%;
    margin: 0.5%;
    @include breakpoints-min(md) {
      display: block;
      width: 100%;
      margin: 0;
    }
    + .ec-headerBanner__item {
      @include breakpoints-min(md) {
        margin-top: 5px;
      }
    }
    a {
      &:hover {
        img {
          @include breakpoints-min(md) {
            opacity: $opacity-default;
          }
        }
      }
    }
    img {
      display: block;
      width: 100%;
      margin: auto;
      transition: $transition-default;
    }
  }
}
