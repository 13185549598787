@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  @include container;
  @include breakpoints-max(md) {
    margin-bottom: 15px;
  }
  &--sub {
    @include breakpoints-max(md) {
      margin-bottom: 40px;
    }
  }
  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__profile {
    margin-left: 0;
    @include breakpoints-min(lg) {
      margin-left: 16px;
    }
  }
  &__title {
    margin-bottom: 10px;
    .ec-shelfRole--image & {
      margin-top: 10px;
    }

    .ec-headingTitle {
      .ec-shelfRole--image & {
        font-size: 1.5rem;
        margin: 0 0 10px;
      }
      .ec-shelfRole--title & {
        font-size: 1.5rem;
        margin: 0 0 10px;
      }
      &__link {
        font-weight: bold;
        font-size: 2.5rem;
        color: $font-link-color;
        //@include hover__text-underline(span,$font-link-color);
        &:hover {
          text-decoration: underline;
        }
        .ec-shelfRole--image & {
          font-size: 1.5rem;
        }
        .ec-shelfRole--title & {
          font-size: 1.5rem;
        }
      }
    }
    .ec-headingTitle__sub {
      margin: 0 0 5px;
      font-size: 1.7rem;
      .ec-shelfRole--image &, .ec-shelfRole--title & {
        font-size: 1.2rem;
      }
      &__link {
        color: #5f5f5f;
        font-weight: bold;
        //@include hover__text-underline(span, #5f5f5f);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .ec-headingTitle__detail {
      background-color: #fff;
      margin: 0 0 5px;
      padding: 3px 5px;
      font-weight: bold;
    }
  }
  & &__tags {
    margin: 0;
    padding: 0;
  }
  & &__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    color: #525263;
    border: none;
    border-radius: 3px;
    background-color: #F5F7F8;
    margin: 2px 0;
    &:last-child {
      margin-bottom: 15px;
    }
  }
  & &__priceRegular {
    padding-top: 14px
  }
  & &__priceRegularTax {
    margin-left: 5px;
    font-size: 10px;
  }
  & &__price {
    color: $warning-color;
    text-align: right;
  }
  & &__code {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc;
  }
  & &__category {
    a {
      color: $warning-color;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li {
      margin-bottom: 5px;
    }
  }
  & &__actions {
  }

  &__in_stock, &__no_stock {

    #page_product_detail & {
      padding: 14px 0 10px;
    }

    #page_product_list & {
      margin-top: auto;
    }
  }

  &__in_stock {

    .ec-productRole__actions {
      width: 100%;
      #page_product_detail & {
        padding-bottom: 10px;
      }
      .ec-select__inline {
        &:before {
          top: 38%;
        }
      }
    }

    .ec-productRole__favorite {
      width: 100%;
    }

  }

  &__no_stock {
    display: flex;
    flex-wrap: wrap;
    #page_product_list & {
      justify-content: flex-end;
    }
    #page_product_detail & {
      flex-direction: column;
    }

    .ec-productRole__actions {
      #page_product_detail & {
        width: calc(100% - 10em );
        padding-bottom: 10px;
        margin-left: auto;
      }

      #page_product_list .ec-shelfRole--title & {
        width: 60%;
      }
      #page_product_list .ec-shelfRole--image & {
        width: 100%;
      }
    }
    .ec-productRole__favorite {
      width: 10em;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      #page_product_detail & {
        margin-left: auto;
      }
    }
  }

  //& &__favorite {
  //  width: 15em;
  //}
  & &__description {
    margin-top: 16px;
    margin-bottom: 16px;
    word-break: break-word;
    h3 {
      font-size: 1.8rem;
      margin: 0 0 4px 0;
      font-weight: bold;
    }
    p {
      margin: 0 0 15px 0;
    }
  }

  &__category-title {
    background-color: #000;
    margin: 0;
    color: #fff;
    padding: 5px 20px 0 5px;
    font-size: 2rem;
    letter-spacing: 0.02em;
    font-weight: bold;
  }

  &__add-cart {
    display: flex;
    @include breakpoints(md,lg) {
      .ec-shelfRole--image & {
        display: block;
      }
    }
    .quantity-text {
      .ec-shelfRole--image & {
        display: none;
        @include breakpoints(md,lg) {
          display: flex;
        }
      }
      .ec-shelfRole--title & {
        @include breakpoints-max(sm) {
          display: none;
        }
      }
    }
    .add-cart {
      span {
        .ec-shelfRole--image & {
          display: none;
        }
        .ec-shelfRole--title & {
          @include breakpoints-max(sm) {
            display: none;
          }
        }
      }
    }
    .ec-numberInput {
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
      margin-left: auto;
      margin-bottom: 0;
      .ec-shelfRole--title &, .ec-shelfRole--image & {
        width: 100%;
      }
      @include breakpoints(md,lg) {
        .ec-shelfRole--image & {
          margin-bottom: 5px;
        }
      }
      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        width: 2em;
        .ec-shelfRole--title &, .ec-shelfRole--image & {
          margin-right: 5px;
        }
      }
      select {
        width: 100px;
        height: 100%;
        max-width: none;
        margin-bottom: 0 !important;
        .ec-shelfRole--title &, .ec-shelfRole--image & {
          width: 100%;
        }
      }
    }
    .ec-productRole__btn {
      width: 100%;
    }
  }

  & &__add-cart {
    .ec-productRole__btn {
      width: 50%;
    }
  }

  &__btn {
    button {
      border-radius: $border-radius-default;
    }
    &--favorite {
      #page_product_detail & {
        text-align: right;
      }
      .ec-shelfRole--title & {
        margin-top: 10px;
      }

      .ec-shelfRole--image & {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 1px 3px;
        background: rgba(255,255,255,0.8);
        margin: 1px;
        border-radius: 5px;
      }

      button {
        position: relative;
        padding: 0 0 0 2.5rem;
        text-align: left;
        background: none;
        border: none;
        text-decoration: underline;
        color: $font-link-color;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        margin-left: auto;
        &:before {
          position: absolute;
          left: 0;
          display: inline-block;
          content: "\f005";
          font-family: "Font Awesome 5 Free";
          font-size: 1.7rem;
        }
        &.favorited {
          #page_product_detail & {
            text-decoration: none;
            font-size: 1.2rem;
          }
          &:before {
            font-weight: 900;
          }
        }
      }
      .plg-favorite-add__number {
        font-size: 1.3rem;
        color: $font-link-color;
      }
      .plg-favorite-count {
      }
    }
  }
  &__detail-btn {
    a {
      background-color: #b5b5b5;
      color: #fff;
      border-radius: 5px;
      border: none;
      display: block;
      text-align: center;
      padding: 5px;
      margin-top: 10px;
    }
    &-stock-find {
      a {
        background-color: #ff5c00;
      }
    }
  }


  &__actions {
    .ec-select {
      select {
        width: 100%;
      }
    }
  }
  &__grid {
    @include breakpoints-min(lg) {
      display: flex;
    }
    &__right {
      position: relative;
      min-height: 1px;
      @include breakpoints-min(lg) {
        width: 300px;
      }
      .ec-content__frame__inner {
        padding: 0;
      }
      img {
        margin: auto;
        display: block;
      }
    }
    &__left {
      position: relative;
      min-height: 1px;
      @include breakpoints-min(lg) {
        width: calc(100% - 300px);
      }
    }
    .product-details {
      @include breakpoints-min(lg) {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        dl {
          padding-left: 10px;
        }
        .product-label,
        .product-year,
        .product-cat-no,
        .product-format,
        .product-country,
        .product-condition {
          width: 50%;
        }
        .product-label,
        .product-cat-no,
        .product-country {
          border-right: 2px solid #ffffff;
        }
        .product-note, .product-sample {
          width: 100%;
        }
      }
    }
  }

  .ec-content__title-img {
  }
  .ec-content__frame__inner {
    background-color: $bg-main-content;
  }
  .ec-sliderItemRole {
    padding: 0;
    .item_visual {
      //padding-bottom: 10px;
    }
  }
}

.ec-productTracklist {
  margin-bottom: 15px;
  li {
    list-style: none;
    word-break: break-word;
    font-size: 1.2rem;
    letter-spacing: 0.03em;
    + li {
      margin-top: 5px;
    }
  }
  .tracklistarea_create {
    margin: 0;
    padding: 0;
    + .tracklistarea_create {
      margin-top: 10px;
    }
  }
  .finding {
    background-color: #5f5f5f;
    margin: 0 0 5px;
    padding: 5px 10px;
    font-size: 1.7rem;
    color: #ffff;
  }
  .ec-content__frame__inner {
    background-color: $bg-sub-content;
  }
}

.product-details {
  background-color: $bg-other-content;
  padding: 5px;
  @include clearfix;
  + .product-details {
    margin-top: 10px;
  }
  dl, dt, dd, p {
    line-height: 1;
    font-size: 1.3rem;
  }
  dl, p {
    margin: 0;
    clear: both;
    min-height: 1em;
  }
  p {
    padding: 3px 5px;
  }
  dl {
    padding: 10px 5px;
    border-bottom: 2px solid #fbfbfb;
    &:last-child {
      border-bottom: none;
    }
  }
  dt, dd {
    word-break: break-all;
  }
  dt {
    float: left;
    width: 6em;
    font-weight: bold;
  }
  dd {
    margin-left: 7em;
    min-height: 1em;
  }
  .product-sample {
    dd {
      #page_product_detail & {
        //詳細ページのみ
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        > div {
          width: 45%;
        }
      }

      .audio-area {
        margin-top: 10px;
        #page_product_detail & {
          //詳細ページのみ
          flex: 1;
          margin-top: 0;
          padding-left: 10px;
        }
      }
    }
  }
  .ec-price {
    &__price {
      padding-left: 0;
    }
  }
  #page_product_detail & {
    .product-category, .product-condition {
      dd {
        min-height: 2em;
      }
    }
  }
}

.product-description_list {
  font-size: 1.3rem;
}
