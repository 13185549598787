@import "../mixins/media";
/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole{
  $this: &;
  margin-bottom: 0;
  padding: 0;
  @include media_desktop {
    @include container;
  }
  & &__infos{
    @include container;
    display: flex;
    border-top: 0;
    margin-bottom: 16px;
    padding-top: 5px;
    flex-direction:column;
    border-top: 1px solid #ccc;
    @include media_desktop {
      padding-left: 0;
      padding-right: 0;
      padding-top: 16px;
      flex-direction:row;
    }
  }
  & &__results {
    width: 100%;
    @include media_desktop {
      margin-bottom: 0;
      width: 50%;
    }
    //@include breakpoints-min(md) {
    //  display: flex;
    //  justify-content: space-between;
    //}
  }
  & &__counter{
    margin-bottom: 15px;
    //@include breakpoints-min(md) {
    //  width: 50%;
    //  margin-bottom: 0;
    //}
  }
  &__switch-disp {
    font-size: 1.4rem;
    //@include breakpoints-min(md) {
    //  width: 50%;
    //  text-align: right;
    //}
    .switch-disp {
      padding: 0 0 0 25px;
      display: inline-block;
      min-height: 15px;
      margin-bottom: 5px;
      font-weight: bold;
      &__title {
        @extend .switch-disp;
        background: url(/html/user_data/packages/default/img/customize/img_title.jpg) no-repeat scroll 5px 2px/ 15px transparent;
      }
      &__image {
        @extend .switch-disp;
        background: url(/html/user_data/packages/default/img/customize/img_gazo.jpg) no-repeat scroll 5px 2px/ 15px transparent;
      }
    }
  }
  & &__actions{
    text-align: right;
    width: 100%;
    @include media_desktop {
      width: 50%;
    }
  }
  &__search-more, &__quality, &__switch-word {
    margin-right: 8px;
    margin-left: 8px;
    @include breakpoints-min(md) {
      margin-right: 0;
      margin-left: 0;
    }
  }
  &__search-more, &__quality,  &__switch-word {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &__quality {
    position: relative;
    @include breakpoints-min(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__search-more {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% - 9em);
      height: 1px;
      background-color: #000000;
      top: 50%;
      left: 9em;
    }

    span {
      font-size: 15px;
      font-weight: bold;
      i {
        padding-right: 5px;
      }
    }
  }

  &__item {
    position: relative;
    width: 100%;
    margin-top : 10px;
    @include breakpoints-min(lg) {
      margin-top: 0;
      #{$this}__quality & {
        &:nth-child(1) {
          width: 25%;
        }
        &:nth-child(2) {
          width: 50%;
        }
        &:nth-child(3) {
          width: 20%;
        }

        + #{$this}__item {
          margin-left: 2%;
        }
      }
    }

    &__title {
      font-weight: bold;
      #{$this}__switch-disp & {
        position: initial;
        display: inline-block;
        padding: 0 5px;
        color: #fff;
        background-color: #b5b5b5;
      }
      &__sp {
        @include breakpoints-min(lg) {
          display: none;
        }
      }
    }

    &__form {
      #{$this}__switch-word & {
        display: flex;
        flex-wrap: wrap;
        .switch-word {
          display: block;
          border: 1px solid #b5b5b5;
          padding: 5px 5px;
          background-color: #fff;
          color: #b5b5b5;
          margin-right: 5px;
          margin-left: 5px;
          margin-bottom: 5px;
          text-align: center;
          width: 9%;
          @include breakpoints-max(md) {
            padding: 2px 0;
            margin-right: 3px;
            margin-left: 3px;
            &[data-value="num"] {
              padding: 4px 0;
              font-size: 10px;
              line-height: 14px;
            }
          }
          @include breakpoints-min(md) {
            width: 10%;
            &[data-value="num"] {
              width: calc(20% + 10px);
            }
          }
          @include breakpoints-min(lg) {
            width: 5%;
            &[data-value="num"] {
              width: calc(10% + 10px);
            }
          }
          &:hover {
            background-color: $bg-color-dark;
            border-color: $bg-color-dark;
            color: #fff;
          }
          &.active {
            background-color: #000000;
            border-color: #000000;
            color: #fff;
            //background-color: $font-link-color;
            //color: #ffffff;
          }
        }
      }
    }
    > dl {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0;
      dt {
        width: 14%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      dd {
        width: 85%;
      }
    }
  }

  #Format, #Jkt, #Vinyl {
    display: flex;
    flex-wrap: wrap;
    .form-check {
      flex: 1;
      text-align: center;


      label {
        border: 1px solid #b5b5b5;
        padding: 5px 10px;
        background-color: #fff;
        color: #b5b5b5;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        @include breakpoints-max(md) {
          padding: 2px 0;
        }
      }
      input[type=checkbox] {
        display: none;
        @include breakpoints-min(lg) {
          &:hover {
            + label {
              background-color: $bg-color-dark;
              border-color: $bg-color-dark;
              color: #fff;
            }
          }
        }
        &:checked {
          + label {
            background-color: #000000;
            border-color: #000000;
            color: #fff;
          }
        }
      }
    }
  }

  #Jkt, #Vinyl {
    .form-check {
      + .form-check {
        label {
          border-left: none;
        }
      }
    }
  }

  #Jkt {
    .form-check {
      label {
        border-bottom: none;
      }
    }
  }

  #Format {
    .form-check {
      @include breakpoints-max(lg) {
        + .form-check {
          label {
            border-left: none;
          }
        }
      }
      @include breakpoints-min(lg) {
        flex: none;
        width: 50%;
        &:nth-child(-n+2) {
          label {
            border-bottom: none;
          }
        }
        &:nth-child(2n) {
          label {
            border-left: none;
          }
        }
      }
    }
  }

  &__search-execution {
    text-align: center;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    button {
      background-color: #b5b5b5;
      color: #fff;
      border-radius: 5px;
      border: none;
      width: 80%;
      padding: 8px 0;
      @include breakpoints-min(lg) {
        width: 100%;
      }
    }
  }
}

